<template>
  <div>
    <div
        v-if="!loadingNow"
        class="row items-center q-pr-xs"
    >
      <img
          class="show-on-mobile q-mr-sm"
          :src="appOptions.securityLogo"
          style="max-width: 70px; max-height: 100%; object-fit: contain;"
      >

      <div class="col text-left q-px-xs">
        <h5 class="q-my-none text-weight-bold">
          {{ $t('Hello!') }}
        </h5>

        <p class="q-mb-none text-caption">
          {{ $t('Sign in to your account') }}
        </p>
      </div>

      <q-btn
          color="light-blue-9"
          size="lg"
          class="q-px-sm"
          no-caps
          :disabled="disabled"
          :label="$t('Badge')"
          @click="changeSlide"
      />
    </div>

    <div
        v-if="error && !loadingNow"
        class="q-pt-md text-red text-center text-subtitle1"
    >
      {{ error }}
    </div>

    <div
        v-if="loadingNow"
        class="q-ma-xl"
    >
      <q-spinner
          color="light-blue-9"
          size="3rem"
          class="q-mr-md"
      />
    </div>

    <form
        v-else
        class="q-py-lg"
        @submit="checkForm"
    >
      <q-carousel
          v-model="slide"
          transition-prev="scale"
          transition-next="scale"
          swipeable
          animated
          infinite
          height="auto"
          class="bg-transparent full-width"
      >
        <q-carousel-slide
            name="default"
            class="q-pa-none q-px-xs"
        >
          <q-input
              v-model="credential"
              type="email"
              color="light-teal"
              :label="$t('Email')"
              class="q-mb-sm"
              autocomplete="email"
              required
          >
            <template v-slot:prepend>
              <q-icon name="email" color="light-grey-10"/>
            </template>
          </q-input>

          <q-input
              v-model="password"
              :type="isPwd ? 'password' : 'text'"
              :label="$t('Password')"
              color="light-teal"
              class="q-mb-sm"
              autocomplete="password"
              required
          >
            <template v-slot:prepend>
              <q-icon name="https" color="light-grey-10"/>
            </template>

            <template v-slot:append>
              <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  color="light-grey-10"
                  @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
        </q-carousel-slide>

        <q-carousel-slide
            name="logWithToken"
            class="q-pa-none"
        >
          <div class="row q-mb-sm full-width q-px-xs">
            <div class="col-9">
              <q-input
                  v-model="token"
                  type="text"
                  :label="$t('Token')"
                  color="light-teal"
                  required
              >
                <template v-slot:prepend>
                  <q-icon name="https" color="light-grey-10"/>
                </template>
              </q-input>
            </div>

            <div class="col-3 q-pl-sm">
              <q-btn
                  outline
                  style="color: goldenrod;"
                  class="fit"
                  icon="camera"
                  @click="openCloseCamera"
              />
            </div>
          </div>
        </q-carousel-slide>
      </q-carousel>

      <div class="row items-center justify-center full-width">
        <div class="col-12 q-mb-sm q-px-xs" v-if="appOptions.locales.length > 1">
          <q-select
              ref="localeSelect"
              color="light-teal"
              :model-value="appOptions.locale.name"
              :options="appOptions.locales || []"
              :label="$t('Language')"
          >
            <template v-slot:prepend>
              <q-icon name="language" color="light-grey-10"/>
            </template>

            <template v-slot:option="props">
              <div
                  class="q-pa-sm card--clickable card--clickable-no-scale"
                  :class="props.selected ? 'bg-success' : ''"
                  @click="setLocale(props.opt)"
              >
                {{ props.opt.name }}
              </div>
            </template>
          </q-select>
        </div>

        <div class="col q-mb-sm q-px-xs"
             v-if="appOptions.domain !== 'smartprepcenter' && appOptions.servers.length > 1">
          <q-select
              ref="serverSelect"
              color="light-teal-10"
              :model-value="optionName"
              :options="appOptions.servers"
              :label="$t('Server')"
          >
            <template v-slot:prepend>
              <q-icon name="settings" color="light-grey-10"/>
            </template>

            <template v-slot:option="props">
              <div
                  class="q-pa-sm card--clickable card--clickable-no-scale"
                  :class="props.selected ? 'bg-success' : ''"
                  @click="setServer(props.opt)"
              >
                {{ props.opt.name }}
              </div>
            </template>
          </q-select>
        </div>

        <div
            v-if="isWorkspacesEnable"
            class="col-12 col-sm-6 q-mb-sm q-px-xs"
        >
          <q-input
              :model-value="appOptionsKey"
              type="text"
              color="light-teal-10"
              :label="$t('Workplace code')"
              :debounce="300"
              @update:model-value="handleWorkplaceChange"
          >
            <template v-slot:prepend>
              <q-icon name="workspaces" color="light-grey-10"/>
            </template>
          </q-input>
        </div>

        <div v-if="isUnlockedDomain" class="col-12 q-mb-sm q-px-xs">
          <q-input
              :model-value="appOptions.domain"
              type="text"
              color="light-teal-10"
              :label="$t('Domain')"
              @update:model-value="handleChangeDomain"
          >
            <template v-slot:prepend>
              <q-icon name="place" color="light-grey-10"/>
            </template>
          </q-input>
        </div>

        <div v-if="appOptions.themes.length > 1" class="col-12 q-mb-sm q-px-xs">
          <q-select
              ref="themeSelect"
              color="light-teal-10"
              :model-value="appOptions.theme"
              :options="appOptions.themes"
              :label="$t('Theme')"
          >
            <template v-slot:prepend>
              <q-icon name="dark_mode" color="light-grey-10"/>
            </template>

            <template v-slot:option="props">
              <div
                  class="q-pa-sm card--clickable card--clickable-no-scale"
                  :class="props.selected ? 'bg-success' : ''"
                  @click="setTheme(props.opt)"
              >
                {{ props.opt.name }}
              </div>
            </template>
          </q-select>
        </div>
      </div>

      <div class="q-my-lg q-px-xs">
        <q-btn
            color="light-blue-9"
            size="lg"
            class="full-width"
            type="submit"
            no-caps
            :label="$t('Sign in')"
        />
      </div>

      <div
          v-if="!disabled"
          class="border-bottom border--light-teal q-pb-xs q-mb-sm"
      >
        {{ $t('Don\'t have an account?') }}

        <router-link
            to="/register"
            class="text-weight-bold text-dark"
            style="text-decoration: none;"
        >
          {{ $t('Create') }}
        </router-link>
      </div>

      <div
          class="hide-on-mobile"
      >
        <small>
          <a
              :href="(appOptions || {}).status || 'https://statuspage.freshping.io/53709-Orderadmin'"
              target="_blank"
              class="text-dark"
          >
            {{ $t('System status page') }}
          </a>
        </small>
      </div>
    </form>

    <q-dialog
        v-model="isOpenCamera"
        persistent
        :maximized="true"
        transition-show="slide-up"
        transition-hide="slide-down"
    >
      <q-card class="bg-primary text-white">
        <q-bar>
          <q-toolbar-title>
            {{ $t('Barcode') }}
          </q-toolbar-title>

          <q-space/>

          <q-btn
              v-close-popup
              dense
              flat
              icon="close"
          >
            <q-tooltip content-class="bg-white text-primary">
              {{ $t('Close') }}
            </q-tooltip>
          </q-btn>
        </q-bar>

        <barcode-scanner
            v-if="isOpenCamera"
            @change="logIt"
        />
      </q-card>
    </q-dialog>

    <credentials-modal ref="credentialsModal" @submit="handleCodeSubmit"/>

    <qr-code-modal ref="QRCodeModal" @submit="handleCodeSubmit"/>
  </div>
</template>

<script>
// Vuex
import { defineAsyncComponent } from 'vue'

// Configs
import workspaces from './../../../config/Workspaces'

// Utils
import { getObject as Cookies } from 'quasar/src/plugins/Cookies'

// Components
import QrCodeModal from '../components/modals/QRCodeModal.vue'
import CredentialsModal from '../components/modals/CredentialsModal.vue'

export default {
  name: 'Login',
  components: {
    BarcodeScanner: defineAsyncComponent(() => import('./../components/barcode-scanner/BarcodeScanner')),
    QrCodeModal,
    CredentialsModal
  },
  data () {
    return {
      appOptionsKey: this.$appOptions.id,
      isUnlockedDomain: window.isUnlockedDomain,
      isWorkspacesEnable: window.isWorkspacesEnable,
      workspaces,
      error: '',
      slide: 'default',
      optionName: '',
      appOptions: this.$appOptions,
      credential: '',
      password: '',
      token: null,
      logWithToken: false,
      isOpenCamera: false,
      loadingNow: false,
      subscriber: null,
      code: undefined,
      isPwd: true,
      disabled: false
    }
  },
  watch: {
    // It is required to know what is value with which we login
    slide (value) {
      this.logWithToken = value !== 'default'
    },
    '$route.query': {
      handler (to) {
        if (to.refreshToken) {
          this.token = to.refreshToken

          this.loginByToken()
        }
      },
      deep: true
    },
  },
  mounted () {
    const event = this.$eventBus.getEventValue('rules')

    if (event.error) {
      this.error = typeof event.error === 'string'
          ? event.error
          : event.error.message
    }

    this.subscriber = this.$eventBus.subscribe('rules', data => {
      this.appOptions = { ...data.appOptions }
    })

    this.loadServer()
    this.isDisabledBadge()

    if (this.$route.query.refreshToken) {
      this.token = this.$route.query.refreshToken

      this.loginByToken()
    }
  },
  unmounted () {
    this.token = null

    this.subscriber.unsubscribe()
  },
  methods: {
    handleWorkplaceChange (text) {
      this.appOptionsKey = text
      const workplace = workspaces.find(({ id }) => id === this.appOptionsKey)

      if (workplace) {
        this.handleAppConfigChange(workplace)
      }
    },
    handleChangeDomain (domain) {
      this.updateAppOptions({ domain })
    },
    // Set current server name
    loadServer () {
      const server = this.appOptions.servers.find(server => {
        return server.domain === this.appOptions.domain && server.host === this.appOptions.defaultServer
      }) || { name: '' }

      this.optionName = server.name
    },
    handleAppConfigChange (config) {
      this.$appOptions = {
        ...this.$defaultAppOptions,
        ...config
      }

      this.appOptions = this.$appOptions
      window.appOptions = this.appOptions
      this.$eventBus.update('rules', { appOptions: this.$appOptions })
      localStorage.setItem('appOptions', JSON.stringify(this.appOptions))
      this.loadServer()
    },
    updateAppOptions (update) {
      this.appOptions = {
        ...this.appOptions,
        ...update
      }

      this.$eventBus.update('rules', { appOptions: this.appOptions })
      window.appOptions = this.appOptions
      localStorage.setItem('appOptions', JSON.stringify(this.appOptions))
    },
    changeSlide () {
      this.slide = this.slide === 'default'
          ? 'logWithToken'
          : 'default'
    },
    logIt (barcode) {
      this.token = barcode
      this.loginByToken()
      this.isOpenCamera = false
    },
    openCloseCamera () {
      this.isOpenCamera = !this.isOpenCamera
    },
    loginByToken () {
      if (this.loadingNow) {
        return
      }

      this.loadingNow = true

      return this.$userService.refreshAuth(this.token, this.appOptions.defaultServer)
          .then(result => {
            return this.doLogin(result)
          })
          .catch(error => {
            this.loadingNow = false
            this.error = error.message
          })
          .finally(() => {
            this.loadingNow = false
          })
    },
    setServer (option) {
      this.optionName = option.name

      const findedServer = this.appOptions.servers.find(server => server.name === this.optionName)
      console.log(findedServer.domain)
      this.updateAppOptions({
        defaultServer: findedServer.host,
        domain: findedServer.domain
      })

      this.$refs.serverSelect.hidePopup()
    },
    setLocale (locale) {
      this.updateAppOptions({ locale })
      this.$i18n.locale = this.appOptions.locale.locale
      this.$refs.localeSelect.hidePopup()
    },
    setTheme ({ theme }) {
      this.updateAppOptions({ theme })
      this.$refs.themeSelect.hidePopup()
    },
    handleCodeSubmit (code) {
      return this.checkForm(undefined, code)
    },
    doLogin (user) {
      user.expiresTimestamp = user.expires_in
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('appOptions', JSON.stringify(this.appOptions))

      const cookie = this.$q.cookies
          ? this.$q.cookies
          : Cookies()

      cookie.set('server', this.appOptions.defaultServer)
      cookie.set('locale', this.$i18n.locale)
      cookie.set('user', user, user.expires_in)

      this.$userService.getById(0)
          .then(userdata => {
            localStorage.setItem('userData', JSON.stringify(userdata))

            if (this.logWithToken && userdata.allowedAdapters && userdata.allowedAdapters === 'TerminalAdapter') {
              localStorage.setItem('isLoginWithToken', true)
            }

            this.loadingNow = false
            const isEmployee = !!(userdata.roles || []).find(({ id }) => id === 7)

            this.$eventBus.update('rules', {
              user: userdata,
              app: isEmployee && window.innerWidth < 700 ? 'terminal' : 'app',
              route: '/'
            })
          })
          .catch(error => {
            this.error = error.message
            this.loadingNow = false
          })
    },
    isDisabledBadge () {
      this.disabled = false

      // const options = getRouteOptions('login')
      //
      // if (options.additionalProperties && options.additionalProperties.disabledFor) {
      //   const disabledFor = options.additionalProperties.disabledFor
      //
      //   if (disabledFor.appOptions && disabledFor.appOptions.includes(this.appOptions.id)) {
      //     this.disabled = true
      //     return
      //   }
      // }

      return
    },
    checkForm (e, code = this.code, grantType) {
      if (e) {
        e.preventDefault()
      }

      if (this.logWithToken) {
        return this.loginByToken()
      }

      if (this.password.length > 0) {
        this.loadingNow = true
        this.$userService.login(this.credential, this.password, undefined, code, grantType)
            .then(user => {
              return this.doLogin(user)
            })
            .catch(error => {
              this.loadingNow = false

              if (error.status === 402) {
                return this.$refs.QRCodeModal.open(error.message, error.errorType)
              }

              if (error.status === 403) {
                return this.$refs.credentialsModal.open()
              }

              this.error = error.message
            })
            .finally(() => {
              this.code = undefined
            })
      }
    }
  }
}
</script>
